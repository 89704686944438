@import "../../../styles/mixins";

.progress_bar_top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  progress {
    margin-top: 10px;
    border-radius: 7px;
    width: 50%;
    height: 8px;
    background-color: rgba(255, 255, 255, 0.12);

    &::-webkit-progress-bar {
      background-color: rgba(255, 255, 255, 0.12);
      border-radius: 7px;
    }

    &::-webkit-progress-value {
      background: linear-gradient(135deg, $primary 0%, $secondary 100%);
      border-radius: 7px;
    }

    &::-moz-progress-bar {
      background: linear-gradient(135deg, $primary 0%, $secondary 100%);
      border-radius: 7px;
    }
  }
}

.progress_bar {
  width: 100%;
  height: 4px;
  margin: 0 16px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.12);

  &::-webkit-progress-bar {
    background-color: rgba(255, 255, 255, 0.12);
    border-radius: 4px;
  }

  &::-webkit-progress-value {
    background: linear-gradient(135deg, $primary 0%, $secondary 100%);
    border-radius: 4px;
    transition: width 0.3s ease;
  }

  &::-moz-progress-bar {
    background: linear-gradient(135deg, $primary 0%, $secondary 100%);
    border-radius: 4px;
  }
}

.progress_button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  border-radius: 50px;
  padding: 12px 36px;
  border: none;
  background: linear-gradient(135deg, $primary 0%, $secondary 100%);
  color: white;
  transition: all 0.3s ease;
  white-space: nowrap;
  min-width: 120px;
  height: 44px;
  
  &:hover:not(:disabled) {
    background: linear-gradient(135deg, $primary 0%, $primary 100%) !important;
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(85, 0, 171, 0.3);
  }
  
  &:active:not(:disabled) {
    transform: translateY(0);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background: linear-gradient(135deg, $primary 0%, $secondary 100%);
  }

  span {
    color: white;
  }
}

.progress_button_disabled {
  @extend .progress_button;
  background: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.3);
  cursor: not-allowed;
  opacity: 0.7;

  &:hover {
    background: rgba(255, 255, 255, 0.12) !important;
    transform: none;
    box-shadow: none;
  }

  span {
    color: white;
  }
}

.progress_button_loading {
  @extend .progress_button;
  padding: 10px 16px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  background: linear-gradient(135deg, $primary 0%, $secondary 100%);

  &:hover {
    background: linear-gradient(135deg, $primary 0%, $primary 100%) !important;
  }

  span {
    color: white;
  }
}

.loading_container {
  display: flex;
  align-items: center;
  gap: 8px;

  .loading_text {
    font-size: 14px;
    color: #fff;
    margin-left: 4px;
  }
}

@media (max-width: 770px) {
  progress {
    display: none !important;
  }
}
