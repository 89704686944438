@import "../../../styles/mixins";

.music_library_container {
  width: calc(100% - 200px);
  height: 100%;
  margin: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  @include mobile {
    width: 100%;
    gap: 10px;
  }
  &__header {
    &_infos {
      display: flex;
      flex-direction: row;
      gap: 40px;
      align-items: center;
      img {
        height: 120px;
        width: 120px;
      }
      @include mobile {
        h1 {
          font-size: 14px !important;
        }
        p {
          font-size: 12px !important;
        }
      }
      &_details {
        display: flex;
        width: 47%;
        justify-content: space-between;

        @include mobile {
          width: 100%;
        }

        &_text {
          h1 {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;
            font-size: 18px;
          }
          p {
            color: rgb(182, 184, 184);
          }
          h2 {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            margin-bottom: 30px;
            cursor: pointer;
            &:hover {
              opacity: 0.7;
              text-decoration: underline;
            }
          }
        }
        .music_play_all {
          display: flex;
          flex-direction: column;
          align-items: center;

          svg {
            color: $active;
            &:hover {
              opacity: 0.8;
            }
            cursor: pointer;
          }
          p {
            font-size: 14px;
            color: rgb(182, 184, 184);
          }
        }
      }
    }
  }

  .musicTableContainer {
    width: 100%;
    display: flex;
    table {
      width: 100%;
      border-collapse: collapse;
      thead {
        th {
          padding: 8px;
          border-bottom: 1px solid $border-color;
        }
      }

      tbody {
        cursor: pointer;

        tr {
          &:hover {
            background-color: $border-color;
          }

          td {
            padding: 8px;
            .tableImage {
              width: 50px;
              height: 50px;
              border-radius: 4px;
              object-fit: cover; // Ensure the image fits nicely within the given dimensions
            }
          }

          .tableRow {
            display: flex;
            align-items: center;
            &.activeRow {
              background: #f5f5f5; // Example background color for the active row
            }
          }

          .playIcon {
            margin-left: 10px;
            cursor: pointer;
          }

          .duration {
            margin-top: 16px;
            display: flex;
            flex-direction: row;
            align-items: center;
          }

          .playIcon {
            margin-left: 8px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .no_data {
    display: flex;
    flex-direction: column;
    gap: 40px;
    text-align: center;
    align-items: center;
    width: 50%;
    border: 1px solid $primary;
    padding: 20px;
    border-radius: 20px;
    @include mobile {
      width: 100%;
    }
    p {
      span {
        color: $primary;
        font-weight: bold;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .music_list {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__item {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      img {
        height: 40px;
        width: 40px;
      }
    }
    &__info {
      h4 {
        font-size: 14px;
        &.active {
          color: purple; // Purple color when the song is playing
        }
      }
      p {
        font-size: 12px;
      }
    }
  }
  .menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: fit-content;
    border: 1px solid $border-color;
    padding: 10px 20px;
    border-radius: 10px;
    margin-left: -250px;
    background: #000;
    z-index: 100;
    float: left;
    span {
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}

.club_details {
  width: 100%;
  background: #000000f3 !important;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 60% !important;
  border-radius: 10px;
  overflow: hidden !important;
  transition: ease-in-out 400ms;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @include tablet {
    .details {
      opacity: 1 !important;
    }
    .content {
      opacity: 0 !important;
    }
  }

  @include mobile {
    .details {
      opacity: 1 !important;
    }
    .content {
      opacity: 0 !important;
    }
  }
  .details {
    //opacity: 0;
    transition: ease-in-out 400ms;

    width: 100%;

    &__top {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: rgb(26, 26, 26);
      padding-left: 10px;
      padding-bottom: 10px;
    }
    p {
      color: #fff;
      font-size: 18px !important;
      font-weight: 700;
      text-align: left;
      margin-top: 10px;
    }
  }
  .content {
    padding: 10px;
    opacity: 0;
    font-weight: 400 !important;
    font-size: 13px !important;
    line-height: 18px !important;
    color: #fcfdfe !important;
    transition: ease-in-out 400ms;
    opacity: 1 !important;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .close {
    padding: 10px;
  }
}
