.signup_container {
  position: relative;
  bottom: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.signup_left {
  background-image: url("https://loop-markets.directus.app/assets/e4fc6c51-fe95-44de-bbf2-7d709573eae7");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 38px;
    font-weight: 700;
    color: white;
  }
}
.signup_right {
  position: relative;
  //bottom: 43px;
  width: 50%; /* Each div occupies 50% of the container */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  img {
    width: 130px;
  }
  h1 {
    color: white;
    font-size: 20px;
  }
  ul {
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-bottom: 100px;
  }
  ul li {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

.get_started_button {
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 50px;
  padding: 5px 28px;
  border: 2px solid #5500ab;
  color: white;
  font-size: 18px;
  font-weight: 600;
  &:hover {
    transition: 0.5s;
    background: #5500ab;
  }
}

@media (max-width: 820px) {
  .signup_container {
    flex-direction: column;
    bottom: 80px;
  }
  .signup_left {
    width: 100%;
    height: 50vh;
    h1 {
      font-size: 30px;
    }
  }
  .signup_right {
    width: 100%;

    top: 30px;
    h1 {
      font-size: 18px;
    }
  }
}
@media (max-width: 390px) {
  .signup_right {
    ul {
      padding-left: 20px;
    }
  }
}
@media (max-width: 370px) {
  .signup_left {
    h1 {
      text-align: center;
      font-size: 32px;
      font-weight: 700;
      color: white;
    }
  }
}
