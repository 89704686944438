@import "../../../styles/mixins";

.profile {
  width: 100%;
  height: min-content;
  //overflow: scroll;

  margin: 24px 24px 0 24px;
  @include mobile {
    margin: 0;
  }

  .profile_vote {
    display: flex;
    gap: 10px;
    align-items: center;
    margin: 0 0 20px 0;
    @include mobile {
      display: block;
      margin: 0 0 10px 0;
    }
  }
}

.body {
  width: 100%;
  margin: auto;
  padding: 10px 20px;
  //overflow: hidden;

  @include mobile {
    padding: 10px;
  }
}

.header {
  width: 100%;
  min-height: 325px;
  padding: 28px;

  margin: auto;
  background: $secondarybg;
  border-radius: $border-radius;

  position: relative;

  @include mobile {
    max-width: 100%;
    min-height: 125px;
    border-radius: 0;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(30 31 36 / 82%);
    z-index: 1;

    backdrop-filter: blur(10px);
  }
}

.profile_header {
  display: flex;
  flex-direction: column;
  z-index: 2;
  position: relative;
  margin-top: -75px;
  padding: 10px 20px;
  //margin: -75px 40px 0 40px ;

  @include mobile {
    display: block;
    z-index: 2;
  }

  .avatar {
    width: 140px;
    height: 140px;
    background: $mainbg;
    //border: solid 1px #21203E;
    border-radius: 100px;
    z-index: 2;

    @include mobile {
      width: 110px;
      height: 110px;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 100px;
      padding: 5px;
      @include mobile {
        width: auto;
      }
    }
  }

  .content {
    position: relative;

    display: flex;
    flex: 1;
    justify-content: space-between;
    text-align: left;
    z-index: 2;

    @include mobile {
      bottom: 30px;
    }

    .creator {
      width: 100%;
      .details {
        display: flex;
        justify-content: space-between;
        margin: 30px 0px 10px 0px;

        .actions {
          /*  position: absolute;
           top: 0;
           right: 0;
           z-index: 3; */
          button {
            max-width: 135px;
            //width: 97px !important;
            // height: 40px;
            border: 1px solid rgba(105, 89, 230, 0.4) !important;
            border-radius: 50px !important;
            padding: 8px 24px !important;

            @include mobile {
              padding: 8px 10px !important;
            }
          }
        }
      }
      .name {
        display: flex;
        align-items: center;
        gap: 10px;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;

        color: #f6f5ff;
      }

      .type {
        color: #31a2fa;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.32px;

        padding: 8px 0;
      }

      .desc {
        //width: calc(100% - 50px);
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: rgba(246, 245, 255, 0.6);
      }
      .location {
        font-weight: 400;
        font-size: 16px;
        display: flex;

        color: rgba(246, 245, 255, 0.6);
      }

      .socials {
        display: flex;
        gap: 10px;
        margin-top: 10px;
        flex-wrap: wrap;
      }

      .link {
        display: flex;
        gap: 6px;
        align-items: center;
        justify-content: center;
        border: 1px solid #2c2c2c;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */

        &:hover {
          opacity: 0.7;
        }
        letter-spacing: 0.02em;
        color: rgba(197, 198, 202, 1);

        @include mobile {
          height: 30px;
          width: 30px;
        }

        .link_icon {
          width: 26px;
          height: 26px;
          padding: 5px;

          @include mobile {
            height: 20px;
            width: 20px;
          }
        }
      }
      .buttonCTA {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin: 20px 0;
        flex-wrap: wrap;
        justify-content: center;
        width: fit-content;
        border: 1px solid #31a2fa !important;
        border-radius: 10px !important;
        padding: 10px;
        font-size: 14px;

        @include mobile {
          padding: 8px 10px !important;
        }
      }
    }
  }
}

.profile_info_edit {
  bottom: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  justify-content: space-between;
  width: 100%;

  @include mobile {
    bottom: 40px;
  }
}
.buttons_share_edit {
  display: flex;
  gap: 20px;

  @include mobile {
    gap: 10px;
  }
}
@media (max-width: 430px) {
  .profile_info_edit {
    flex-direction: column;
    gap: 20px;
  }
}

.follow_data {
  margin-left: 150px;
  display: flex;
  gap: 20px;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 518px) {
  .follow_data {
    margin-left: 140px;
  }
}
.following {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  @include mobile {
    font-size: 12px;
  }
}
.following_number {
  font-weight: 600;
  font-size: 20px;
  @include mobile {
    font-size: 16px;
  }
}
.followers {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  @include mobile {
    font-size: 12px;
  }
}

.vote_button {
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  padding: 3px 15px;
  gap: 8px;
  font-size: 14px;
  font-weight: bold;
  border: 1px solid $primary;
  border-radius: 50px;
  //background: #151331;
  color: $primary;
  &:hover {
    background: $primary !important;
    color: #fff !important;
  }

  img {
    width: 15px;
    margin: auto;
  }

  span {
    color: var(--blue);
    /* background: linear-gradient(258deg, var(--purple), var(--blue));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
  }
  @include mobile {
    font-size: 14px;
    align-items: center;
    padding: 2px 10px !important;
    margin: 5px 0;
  }
}

.edit_button {
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  font-weight: 500;
  color: #887dea;
  cursor: pointer;
  border-radius: 50px;
  padding: 5px 24px;
  border: 1px solid #332f62;
  //background: #151331;
  &:hover {
    background: #8168e1 !important;
    color: #fff !important;
  }

  img {
    width: 20px;
  }

  span {
    color: var(--blue);
    /* background: linear-gradient(258deg, var(--purple), var(--blue));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
  }
  @include mobile {
    font-size: 14px;
    align-items: center;
    padding: 2px 10px !important;
  }
  @media (max-width: 430px) {
    width: 100px !important;
    position: relative;
    left: 72px;
  }
}

.edit_button_cta {
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  font-weight: 500;
  color: #887dea;
  cursor: pointer;
  border-radius: 50px;
  padding: 5px 24px;
  border: 1px solid #332f62;
  //background: #151331;
  &:hover {
    background: #8168e1 !important;
    color: #fff !important;
  }

  img {
    width: 20px;
  }

  span {
    color: var(--blue);
    /* background: linear-gradient(258deg, var(--purple), var(--blue));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
  }
  @include mobile {
    font-size: 14px;
    align-items: center;
    padding: 2px 10px !important;
  }
}

.tabs {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  z-index: 2;
  overflow: auto;
  border-top: solid 1px $border-color;
  border-bottom: solid 1px $border-color;

  .tab {
    //flex: 1;
    flex: none;
    text-align: center;
    padding: 17px;

    font-weight: 600;
    color: #c5c6ca;
    border-bottom: solid 1px transparent;
    //background: #111023ab;
    transition: ease-in-out 200ms;

    &:hover {
      //background: #21203e80;
      cursor: pointer;
    }
  }

  .active {
    border-bottom: solid 3px $secondary;
    color: $secondary;
    //border-image: linear-gradient(104.36deg, #FCCFF7 10.19%, #E659D6 35.3%, #8877EA 62.03%, #86E2EE 88.37%) 1 !important;
  }
}

.feed {
  width: 100%;
  height: 100%;

  margin: auto;
  text-align: left;

  padding-bottom: 100px;
  padding-top: 50px;

  .feed_item_loading {
    width: $feed-width;
    margin: 40px auto;
    position: relative;

    @include mobile {
      width: 100%;
      max-width: $feed-width;
      padding: 0 20px;
    }
  }

  .feed_item {
    width: $feed-width;
    margin: 60px auto;
    position: relative;

    @include mobile {
      width: 100%;
      max-width: $feed-width;
      padding: 0 20px;
    }

    /*  &::after {
      content: '';
      margin: auto;
      position: absolute;
      bottom: -18px;
      left: 0;
      right: 0;
      width: 100%;
      height: 1px;
      background-color: $border-color;
    } */

    .image_container {
      width: 100%;
      height: 400px;
      min-height: 500px;
      border-radius: 15px;

      overflow: hidden;
      position: relative;

      @include mobile {
        width: 100%;
        //height: 400px;
        min-height: 400px;
      }

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        .heart {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          width: 80px;
          height: 80px;
          margin: auto;
          background-image: url("../../../assets/icons/liked.svg");
          background-size: cover;
          opacity: 0;
          transition: opacity 0.3s ease-in-out;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      @include mobile {
        width: 100%;
      }
    }

    .actions {
      display: flex;
      align-items: center;
      gap: 20px;
      padding: 20px 0;

      img {
        width: 20px;
      }
    }

    .content {
      color: #fff;
      display: flex;
      gap: 12px;

      .creator {
        width: 40px;
        height: 40px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50px;
        }
      }

      span {
        font-weight: 400;
        color: rgba(246, 245, 255, 0.613);
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}
.profile_infos {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 10px;
}
@media (max-width: 430px) {
  .buttons_share_edit {
    position: relative;
    flex-direction: row !important;
    //right: 20px;
    right: 65px;
  }
}

@media (max-width: 574px) {
  .buttons_share_edit {
    flex-direction: column;
  }
}
