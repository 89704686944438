@import "../styles/mixins";

.sidebar {
  display: flex;
  width: 100%;
  height: 100%;

  .bodyContent {
    display: flex;
    height: 100%;
    width: calc(100% - 100px);
    //width: 100%;
    
    margin-top: 0;
    padding-bottom: 50px !important;
    //margin-bottom: 100px;
    overflow-x: hidden;
    position: relative;
    transition: width 0.3s ease;

    @include tablet {
      width: 100%;
      padding: 0 20px;
    }

    @include mobile {
      width: 100%;
      height: calc(100% - 50px);
      padding: 0 !important;
      overflow: initial;
    }
  }

  &:has(.sidebarCollapse) {
    .bodyContent {
      width: calc(100% - 100px);
    }
  }

  &:has(.sidebarCollapsed) {
    .bodyContent {
      width: calc(100% - 80px);

      @include mobile {
        width: 100%;
      }
    }
  }

  .sidebarContent {
    text-align: left;
    width: 300px;
    height: 100vh;
    transition: width 0.3s ease;
    position: relative;
    background-color: var(--background-color);
    z-index: 999;

    @include tablet {
      width: 70px
    }

    @include mobile {
      display: contents;
    }

    .navbar {
      padding: 40px 20px;
      transition: all 0.3s ease;

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          margin: 8px 0;
          
          a {
            display: flex;
            align-items: center;
            gap: 12px;
            padding: 8px;
            border-radius: 8px;
            transition: all 0.3s ease;
            text-decoration: none;
            color: var(--text-color);

            &:hover {
              background: var(--hover-color);
            }

            &.active {
              background-color: $hover-color;
              border-radius: 10px;
              border: 1px solid $border-color;

              @include mobile {
                background-color: transparent;
                border: none;
              }
            }

            &.sidebarCollapse, &.sidebarCollapsed {
              .active {
                background-color: transparent;
                border: none;
              }
            }
          }

          &.active a {
            background: var(--hover-color);
          }
        }
      }
    }

    .socialLinks {
      display: flex;
      gap: 12px;
      padding: 20px;
      flex-wrap: wrap;
      transition: all 0.3s ease;

      @include tablet {
        display: none;
      }

      @include mobile {
        display: none;
      }

      .socialIcon {
        color: var(--text-color);
        &:hover {
          color: var(--primary-color);
        }
      }
    }

    &.sidebarCollapse, &.sidebarCollapsed {
      .navbar {
        padding: 40px 10px;

        @include mobile {
          padding: 0;
        }

        ul li {
          &.active {
            position: relative;

            a {
              background: transparent !important;
              
              &:hover {
                background: transparent !important;
              }
              
              img {
                filter: brightness(1.2);
              }
            }
          }

          a {
            justify-content: center;
            padding: 8px 0;
            border-radius: 0;
            
            &:hover {
              background: transparent;
            }
            
            span {
              opacity: 0;
              width: 0;
              overflow: hidden;
            }
          }
        }
      }

      .socialLinks {
        padding: 20px 10px;
        flex-direction: column;
        align-items: center;
      }
    }

    &.sidebarCollapse {
      width: 100px;
      .navbar {
        min-width: 100px;
        max-width: 100px;
      }
    }

    &.sidebarCollapsed {
      position: relative;
      width: 80px;
      .navbar {
        min-width: 80px;
        max-width: 80px;
        @include mobile {
          min-width: 100%;
          max-width: 100%;
        }
        .active {
          padding: 14px 20px;
          border-radius: 50px;
          border: transparent;
          background: transparent;

          @include mobile {
            padding: 0 20px;
            border: transparent;
            background-color: transparent;
          }
        }
      }

      .collapseButton {
        .rotated {
          transform: rotate(180deg);
        }
      }
    }

    .collapseButton {
      position: absolute; 
      right: 5px;
      top: 20px;
      width: 24px;
      border-radius: 50%;
      background: var(--background-color);
      border: 1px solid var(--border-color);
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.2;
      z-index: 1000;
      transition: transform 0.3s ease;
      img {
        width: 24px;
      }

      svg {
        transition: transform 0.3s ease;
        color: var(--text-color);
      }

      &:hover {
        background: var(--hover-color);
      }
    }

    .walletSidebar {
      width: 100%;
      min-width: 100px;
      max-width: 100px;

      height: calc(100% - 80px);
      padding: 40px 20px;
      background-color: $mainbg;
      margin: auto;
      position: fixed;

      @include mobile {
        display: none;
      }
  
      @include tablet {
        display: none;
      }
    }

    .navbar {
      width: fit-content;
      /* min-width: 300px;
      max-width: 300px; */

      height: 100vh;
      padding: 40px 20px 0;
      background-color: $mainbg;
      border-right: $border;
      border-color: $border-color;
      margin: auto;
      position: fixed;
      display: flex;
      flex-direction: column;

      @include tablet {
        width: 100%;
        min-width: 100px;
        max-width: 100px;
        height: 100%;
        span {
          display: none;
        }
      }

      @include mobile {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        height: $mobileMenuHight;
        padding: 0;
        z-index: 999;
        border-top: solid 1px #ffffff20;

        hr {
          display: none;
        }

        span {
          display: block;
        }

        .logo {
          display: none;
        }

        ul {
          margin-top: 5px !important;
          display: flex;
          justify-content: space-evenly;
        }

        li a {
          display: block !important;
          padding: 0 !important;
        }
      }
    }

    .navbar_hm {
      @include mobile {
        position: inherit !important;
        display: none !important;
      }
    }

    .logo_section {
      display: flex;
      align-items: center;

      gap: 10px;
      font-weight: 500;
      font-size: 18px;
      line-height: 18px;

      color: #ffffff;

      .logo {
        width: 30px;
      }
    }

    .active {
      //padding: 10px 20px;
      border-radius: 50px;
      border: 1px solid $border-color;
      background: $secondarybg;

      @include tablet {
        border: transparent;
        background-color: transparent;
      }
      
      @include mobile {
        padding: 0 20px;
        border-radius: 12px 12px 0 0;
        background-color: transparent;
      }
    }

    li {
      padding: 10px 20px;
      margin: 10px 0;
      @include mobile {
        padding: 0 20px;
      }
    }

    li a {
      display: flex;
      align-items: center;

      gap: 20px;
      font-weight: 500;
      font-size: 18px;
      line-height: 18px;

      color: #C5C6CA;
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.668);
      z-index: -2;
      backdrop-filter: blur(10px);
    }

    .contentDrawer {
      position: absolute;
      top: 75px !important;
      left: 0;
      padding: 0 10px;

      width: 100%;
      min-width: 400px;
      height: 100vh;
      background: $secondarybg;
      border-right: solid 1px $border-color;
      border-left: solid 1px $border-color;
      z-index: -1;
      overflow: hidden;
      transition: ease-in-out 300ms;

      @include mobile {
        min-width: 100%;
        width: 100%;
        top: 70px;
        left: 0 !important;
        bottom: 0 !important;
      }

      .close {
        position: absolute;
        top: 0;
        right: 20px;
      }

      h3 {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;

        color: #F6F5FF;
      }

      .menuDrawer {
        display: flex;
        flex-wrap: wrap;
        text-align: center;

        li {
          width: 50%;
        }
      }
    }

    position: relative;

    @include mobile {
      position: inherit;
    }

    .footer {
      position: absolute;
      bottom: 20px;
      left: 0;
      right: 0;
      width: 100%;

      margin: auto;
      padding: 0 20px !important;

      @include mobile {
        display: none;
        visibility: hidden;
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100% !important;

        img {
          display: none;

          @include tablet {
            display: block;
          }
        }
      }
    }
  }

  .desktopMenu {
    display: block;

    @include mobile {
      display: none !important;

      a {
        text-align: center;
      }
  
      img {
        width: 30px;
      }
    }
  }

  .mobileMenu {
    display: none;
    visibility: hidden;

    a {
      text-align: center;
    }

    img {
      width: 22px;
    }

    @include mobile {
      display: block;
      visibility: visible;
    }
  }

  .socialLinks {
    position: absolute;
    bottom: 100px;
    left: 0;
    width: 100%;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
    background-color: $mainbg;
    border-top: 1px solid $border-color;

    @include tablet {
      width: 100px;
      padding: 12px;
      gap: 12px;
    }

    @include mobile {
      position: static;
      width: 100%;
      padding: 10px;
      gap: 10px;
      margin: 8px 0;
      justify-content: space-around;
      border-top: none;
      background-color: transparent;
    }

    .socialIcon {
      color: #ffffff;
      opacity: 0.8;
      transition: all 0.2s ease;
      padding: 10px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, 0.05);
      
      &:hover {
        opacity: 1;
        background: rgba(255, 255, 255, 0.1);
        transform: translateY(-2px);
      }

      @include tablet {
        padding: 8px;
      }

      @include mobile {
        padding: 8px;
      }

      svg {
        width: 14px;
        height: 14px;

        @include tablet {
          width: 12px;
          height: 12px;
        }

        @include mobile {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
}

.contentDrawer {
  background-color: #141313c3 !important;
  overflow-y: auto;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  
  .header {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    h3 {
      margin: 0;
      font-size: 1.25rem;
    }
    
    .close {
      background: none;
      border: none;
      padding: 0.5rem;
      cursor: pointer;
      transition: opacity 0.2s;
      
      &:hover {
        opacity: 0.7;
      }
    }
  }

  .headerLinks {
    li {
      padding: 0.5rem 1rem;
      display: flex;
      align-items: center;
      a {
        font-size: 1rem;
      }
    }
  }
}

.menuDrawer {
  width: 100%;
  display: block !important;
  list-style: none;
  padding: 0;
  margin: 0;
  //flex-grow: 1;
  
  .menuItem {
    .item {
      width: max-content;
      display: flex;
      align-items: center;
      padding: 0.875rem 0;
      cursor: pointer;
      transition: background-color 0.2s;
      
      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
      
      img {
        width: 24px;
        height: 24px;
        margin-right: 1rem;
      }
      
      span {
        font-size: 1rem;
      }
    }
    
    &.active .item {
      background-color: var(--primary-color-light);
      color: var(--primary-color);
    }
  }
}

.socialLinks {
  padding: 1rem;

  h4 {
    margin: 1rem 0;
    font-size: 1rem;
  }
  
  .socialIcons {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    
    a {
      padding: 0.5rem;
      transition: opacity 0.2s;
      
      &:hover {
        opacity: 0.7;
      }
      
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
}