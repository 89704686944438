@import "../../../../styles/mixins";

.starting {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
  margin-top: 16px;
}

.middle_content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #2c2c2c;
  width: 551px;
  border-radius: 10px;
  background-color: #1d1d1d;
  
  .go_to_my_profile {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    width: 100%;
  }
}

.middle_img {
  background-image: url("https://loop-markets.directus.app/assets/239a1f0b-55df-4dfe-96ab-31eabdaf14f0");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  border-radius: 10px 10px 0 0;
}

.text {
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  
  h1 {
    font-size: 24px;
    color: white;
    margin: 0;
    margin-bottom: 8px;
  }

  p {
    color: #a0a0a0;
    font-size: 16px;
    margin: 0;
  }

  .error_icon {
    font-size: 48px;
    color: #ff4d4d;
    margin-bottom: 16px;
  }
}

.status_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  width: 100%;

  .loader_container {
    margin-top: 24px;
  }

  button {
    margin-top: 24px;

    svg {
      font-size: 20px;
      margin-right: 8px;
    }
  }
}

.button_group {
  display: flex;
  gap: 16px;
  margin-top: 24px;

  a {
    text-decoration: none;
  }
}

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 50px;
  padding: 14px 32px;
  font-size: 16px;
  border: 2px solid transparent;
  transition: all 0.3s ease;
  min-width: 180px;

  &:hover {
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0);
  }
}

.primary_button {
  background: linear-gradient(45deg, $secondary, $secondary);
  color: white;

  &:hover {
    background: linear-gradient(45deg, darken($secondary, 10%), darken($secondary, 10%));
  }
}

.secondary_button {
  background-color: transparent;
  border: 2px solid $secondary;
  color: $secondary;

  &:hover {
    border-color: darken($secondary, 10%);
    color: darken($secondary, 10%);
  }
}

@media (max-width: 580px) {
  .middle_content {
    width: 100%;
    margin: 0 16px;
    border-radius: 10px;
  }
  
  .middle_img {
    border-radius: 10px 10px 0 0;
  }

  .button_group {
    flex-direction: column;
    width: 100%;
    padding: 0 16px;

    a {
      width: 100%;
    }

    .button {
      width: 100%;
    }
  }
}
