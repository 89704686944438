@import "../../../../styles/mixins.scss";
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@500;600;700&display=swap");

.prizes_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 60px;

  .prizes_title {
    h1 {
      font-size: 2rem;
      color: #fff;
      margin-bottom: 1rem;
      text-align: center;
    }
  }
  .prizes_content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    gap: 20px;

    @include mobile {
      gap: 40px;
    }

    .message {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;

      .prizes_info {
        display: flex;
        flex-direction: column;
        gap: 20px;

        h1 {
          font-size: 1.4rem;
          color: #fff;
        }
      }
    }
  }
  .prizes_tab {
    h1 {
      font-size: 1.2rem;
      color: $primary;

      text-align: center;
    }
  }
  .table_container {
    display: flex;
    justify-content: center;
    width: 100%;

    @include mobile {
      justify-content: flex-start;
      overflow: auto;
    }
  }

  .table {
    width: 100%;
    max-width: 800px;
    border-collapse: collapse;

    .star_rank {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column; /* Stack items vertically */
      position: relative;

      span {
        margin-top: -32px; /* Adjust to position text over the icon */
        font-weight: bold;
        color: #282828;
      }
    }
  }

  .table th,
  .table td {
    padding: 8px;
    text-align: center;
  }

  .table th {
    &:nth-child(1) {
      color: gold;
    }
    color: $primary;
  }
  .bottom_message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    p {
      font-size: 1.2rem;
      text-align: center;
      background: linear-gradient(96.68deg, #62d9ec 0%, #9469e0 105.7%), #f6f5ff;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
    span {
      text-align: center;

      font-size: 0.9rem;
    }
  }
}
