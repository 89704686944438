@import "../../../styles/mixins.scss";
// Theme variables
:root {
  --preview-bg-primary: #1d1d1d;
  --preview-bg-secondary: #121212;
  --preview-border: #2c2c2c;
  --preview-text-primary: white;
  --preview-text-secondary: #a0a0a0;
  --preview-header-bg: #1a1a1a;
  --preview-placeholder-bg: #363636;
}

.theme_light {
  --preview-bg-primary: #ffffff;
  --preview-bg-secondary: #f5f5f5;
  --preview-border: #e0e0e0;
  --preview-text-primary: #000000;
  --preview-text-secondary: #666666;
  --preview-header-bg: #ffffff;
  --preview-placeholder-bg: #e0e0e0;
}

.theme_dark {
  --preview-bg-primary: #1d1d1d;
  --preview-bg-secondary: #121212;
  --preview-border: #2c2c2c;
  --preview-text-primary: white;
  --preview-text-secondary: #a0a0a0;
  --preview-header-bg: #1a1a1a;
  --preview-placeholder-bg: #363636;
}

.preview_container {
  width: 100%;
  max-width: 1024px;
  background-color: var(--preview-bg-primary);
  border: solid 1px var(--preview-border);
  height: 75vh;
  min-height: 600px;
  max-height: 900px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: hidden;
  margin: 0 auto;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
  position: relative;

  .themeCustomization {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 10;

    .themeButton {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 10px 16px;
      background: var(--preview-bg-primary);
      border: 1px solid var(--preview-border);
      border-radius: 30px;
      cursor: pointer;
      transition: all 0.2s ease;
      font-size: 14px;
      color: var(--preview-text-primary);
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

      .themePreview {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 2px solid var(--preview-border);
        position: relative;
        overflow: hidden;

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: linear-gradient(45deg, rgba(255, 255, 255, 0.1), transparent);
        }
      }

      span {
        font-weight: 500;
      }

      &:hover {
        transform: translateY(-1px);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
        background: var(--preview-bg-secondary);
      }

      &:active {
        transform: translateY(0);
      }
    }
  }

  .preview_title {
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    border-bottom: 1px solid var(--preview-border);
    
    h1 {
      color: var(--preview-text-primary);
      font-size: 1.5rem;
      margin: 0;
    }
    
    span {
      font-size: 0.875rem;
      color: var(--preview-text-secondary);
    }
  }

  .preview {
    flex: 1;
    background-color: var(--preview-bg-secondary);
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .preview_header {
      padding: 16px 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid var(--preview-border);
      background-color: var(--preview-header-bg);

      .logo_container {
        width: 120px;
        height: 32px;
        //background-color: #363636;
        border-radius: 6px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px;

        .logo_image {
          width: 100%;
          height: 100%;
          object-fit: contain;
          max-width: 100%;
          max-height: 100%;
        }

        .logo_placeholder {
          width: 100%;
          height: 100%;
          background: linear-gradient(45deg, var(--preview-placeholder-bg), var(--preview-placeholder-bg));
        }
      }

      .profile_container {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        background-color: var(--preview-placeholder-bg);

        .profile_image {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .profile_placeholder {
          width: 100%;
          height: 100%;
          background: linear-gradient(45deg, var(--preview-placeholder-bg), var(--preview-placeholder-bg));
        }
      }
    }

    .content_area {
      flex: 1;
      display: flex;
      overflow: hidden;

      .navigation {
        width: 200px;
        padding: 24px 16px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        border-right: 1px solid var(--preview-border);

        .nav_item {
          padding: 12px;
          border-radius: 6px;
          color: var(--preview-text-primary);
          font-size: 0.875rem;
          text-align: center;
          cursor: pointer;
          transition: opacity 0.2s ease;

          &:hover {
            opacity: 0.8;
          }
        }
      }

      .main_content {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        padding: 0;

        .preview_grid {
          width: 100%;
          max-width: 1200px;
          margin: 0 auto;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 24px;
          padding: 24px;

          .grid_item_large {
            grid-column: 1 / -1;
            width: 100%;
            aspect-ratio: 3 / 1;
            background-color: var(--preview-placeholder-bg);
            border-radius: 8px;
            overflow: hidden;
            position: relative;
            height: 300px;

            @include mobile {
              height: 100px;
            }
            
            :global {
              .reactEasyCrop_Container {
                height: 100% !important;
              }
            }
          }

          .grid_item {
            aspect-ratio: 1 / 1;
            background-color: var(--preview-placeholder-bg);
            border-radius: 8px;
          }
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .preview_container {
      width: 90%;
    }
  }

  @media (max-width: 1024px) {
    .preview_container {
      width: 85%;

      .preview {
        .content_area {
          .navigation {
            width: 160px;
            padding: 16px 12px;

            .nav_item {
              padding: 10px;
              font-size: 0.813rem;
            }
          }

          .main_content {
            .preview_grid {
              max-width: 900px;
              padding: 16px;
              gap: 16px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .preview_container {
      width: 100%;
      height: 70vh;
      min-height: 500px;

      .preview {
        .content_area {
          flex-direction: column;

          .navigation {
            width: 100%;
            padding: 12px;
            flex-direction: row;
            overflow-x: auto;
            border-right: none;
            border-bottom: 1px solid var(--preview-border);

            .nav_item {
              min-width: 100px;
              white-space: nowrap;
            }
          }

          .main_content {
            .preview_grid {
              max-width: 100%;
              padding: 16px;
              gap: 16px;

              .grid_item_large {
                height: 200px;
              }
            }
          }
        }
      }
    }
  }
}
