@import "../../../../styles/mixins";
.starting {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  margin-top: 50px;
  padding: 0 20px;
  min-height: 70vh;
  width: 100%;
  box-sizing: border-box;
}

.middle_content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid rgba(28, 28, 28, 0.1);
  width: 100%;
  max-width: 551px;
  border-radius: 24px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
  }
}

.middle_img {
  background-image: url("https://loop-markets.directus.app/assets/eea7f3e5-9581-4d0e-8447-7813b5bc3b5f");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 180px;
  border-radius: 24px 24px 0 0;
  position: relative;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.4) 100%);
    border-radius: 24px 24px 0 0;
  }

  h1 {
    font-size: 32px;
    color: white;
    font-weight: 700;
    position: relative;
    z-index: 1;
    text-shadow: 0 2px 4px rgba(0,0,0,0.2);
    text-align: center;
    padding: 0 20px;
  }
}

.text {
  padding: 32px;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  
  span {
    font-size: 18px;
    line-height: 1.6;
    color: #4a4a4a;
    display: block;
    max-width: 440px;
    margin: 0 auto;
  }
}

.get_started_button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  border-radius: 50px;
  padding: 12px 36px;
  border: none;
  background: linear-gradient(135deg, $primary 0%, $secondary 100%);
  color: white;
  transition: all 0.3s ease;
  white-space: nowrap;
  
  &:hover {
    background: linear-gradient(135deg, $primary 0%, $primary 100%) !important;
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(85, 0, 171, 0.3);
  }
  
  &:active {
    transform: translateY(0);
  }

  svg {
    transition: transform 0.3s ease;
  }

  &:hover svg {
    transform: translateX(4px);
  }
}

.progress_button_loading {
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  font-weight: 500;
  color: #887dea;
  cursor: pointer;
  border-radius: 50px;
  padding: 10px 24px;
  border: 2px solid #8168e1;
  //background: #151331;
  &:hover {
    background: #8168e1 !important;
    color: #fff !important;
  }
  span {
    color: var(--blue);
    /* background: linear-gradient(258deg, var(--purple), var(--blue));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
  }
}

/* Large Desktop */
@media (min-width: 1440px) {
  .middle_content {
    max-width: 650px;
  }

  .middle_img {
    height: 200px;

    h1 {
      font-size: 36px;
    }
  }

  .text span {
    font-size: 20px;
    max-width: 520px;
  }
}

/* Tablet and Small Desktop */
@media (max-width: 1024px) {
  .starting {
    margin-top: 40px;
    gap: 32px;
  }

  .middle_content {
    max-width: 500px;
  }

  .middle_img {
    height: 160px;

    h1 {
      font-size: 28px;
    }
  }
}

/* Mobile Landscape */
@media (max-width: 768px) {
  .starting {
    margin-top: 30px;
    padding: 0 16px;
    gap: 24px;
  }

  .middle_content {
    width: 100%;
  }

  .text {
    padding: 24px 20px;

    span {
      font-size: 16px;
      line-height: 1.5;
    }
  }

  .get_started_button {
    padding: 10px 28px;
    font-size: 15px;
  }
}

/* Mobile Portrait */
@media (max-width: 480px) {
  .starting {
    margin-top: 20px;
    padding: 0 12px;
  }

  .middle_img {
    height: 140px;

    h1 {
      font-size: 24px;
      padding: 0 12px;
    }
  }

  .text {
    padding: 20px 16px;

    span {
      font-size: 15px;
    }
  }

  .get_started_button {
    width: 100%;
    max-width: 280px;
  }
}

/* Small Mobile */
@media (max-width: 320px) {
  .middle_img h1 {
    font-size: 22px;
  }

  .text span {
    font-size: 14px;
  }

  .get_started_button {
    font-size: 14px;
    padding: 10px 24px;
  }
}
