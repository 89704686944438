@import "../../../styles/mixins";

.button {
  width: 212px;
  padding: 15px 24px;
  background: $primary;
  border: solid 1px $primary;
  border-radius: 50px;

  font-weight: 500;
  color: #fff;
  &:disabled {
    opacity: 0.5;
    color: #fff !important;
    &:hover {
      background: transparent !important;
      color: #fff !important;
    }
  }
  &:hover {
    background: #8168e1 !important;
    color: #fff !important;
  }
}

.button_outline {
  background: transparent;
  color: #fff;
  border: solid 2px #887DEA;
  border-radius: 50px;

  &:hover {
    background: #8168e1;
    color: #fff !important;
  }
}

.btn_sm {
  width: fit-content !important;
  //width: 150px !important;
}