@import "../../../../styles/mixins.scss";
.edit_profile_cover {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-size: cover !important;
  background-position: center !important;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(30 31 36 / 82%);
    z-index: 1;

    backdrop-filter: blur(10px);
  }
}

.profile_cover {
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  background: rgb(52, 52, 52);
  justify-content: center;
  display: flex;
  overflow: hidden;
}

.profile_cover .cover {
  height: 100%;
  width: 100%;
  border-radius: 8px;
  object-fit: cover;
}

.cover_crooper {
  height: 100%;
  width: 100%;
  
  :global {
    .reactEasyCrop_Container {
      border-radius: 8px;
      height: 100% !important;
      width: 100% !important;
    }
  }
}

.cover_crooper img {
  object-fit: cover;
  width: 100%;
}

.save_changes_cover {
  z-index: 9;
  position: absolute;
  right: 16px;
  top: 16px;
  display: flex;
  gap: 8px;
}

.save_changes_left {
  display: flex;
  flex-direction: row;
}

.open_cover_update {
  background: white;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  button {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
    cursor: pointer;
    padding: 0;

    &.blue_btn {
      background: #4CAF50;
      border-radius: 50%;
    }
  }

  &:hover {
    opacity: 0.9;
  }
}

@media (max-width: 460px) {
  .save_changes_cover {
    right: 16px;
    top: 16px;
  }
}

// css scale animation
@keyframes jump {
  0% {
    transform: scale(1);
    background: $primary;
  }
  50% {
    transform: scale(1.5);
    background: #7569a2;
  }
  100% {
    transform: scale(1);
    background: $primary;
  }
}
