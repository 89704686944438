@import "../../../../styles/mixins";

.first_step {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 50px;
  padding: 24px 0;

  .first_step_top {
    width: 100%;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    h1 {
      color: white;
    }
    span {
      font-size: 14px;
      color: white;
    }
    hr {
      padding: 0 !important;
      margin: 0 !important;
    }
  }
  .select_images_icon {
    display: flex;
    align-items: stretch;
    justify-content: center;
    gap: 20px;
    padding: 0 16px;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;

    .profile_image_icon,
    .cover_image_icon {
      flex: 1;
      min-width: 0; 
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease;
      border: 2px dashed $border-color;
      background-color: rgba(37, 37, 37, 0.4);
      padding: 20px;
      border-radius: 8px;
      height: 180px; 

      .upload_title {
        font-size: 14px;
        color: #fff;
        margin: 8px 0 4px;
        text-align: center;
      }

      .upload_instruction {
        color: #8e8e8e;
        font-size: 12px;
        margin-bottom: 4px;
        text-align: center;
      }

      span {
        font-size: 11px;
        color: #8e8e8e;
        text-align: center;
      }

      &:hover {
        cursor: pointer;
        border-color: $secondary;
        background-color: rgba(37, 37, 37, 0.6);
        transform: translateY(-2px);
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 16px;
      padding: 0;

      .profile_image_icon,
      .cover_image_icon {
        width: 100%;
        height: 140px;
        padding: 16px;
      }
    }
  }
  .previewSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    flex: 1;
    padding: 0 24px;
    max-width: 1200px;
    margin: 0 auto;

    .previewControls {
      display: flex;
      justify-content: flex-end;
      padding: 0 24px;
    }
  }
  .preview_container {
    width: 70%;
    background: #1d1d1d;
    height: 65vh;
    border-radius: 5px;
    border: 1px solid #2c2c2c;
    display: flex;
    flex-direction: column;
    align-content: center;
    gap: 20px;

    .preview_title {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      span {
        font-size: 14px;
      }
    }
    .preview {
      width: 50%;
      height: 70%;
      background-color: #181818;
      position: relative;
      margin: 0 auto;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
    }
    .preview_cover {
      width: 100%;
      height: 40%;
      background-color: #565656;
      position: relative;
      margin: 0 auto;
      border-radius: 5px 5px 0px 0px;
    }
    .preview_profile_img {
      position: relative;
      bottom: 12%;
      left: 10%;
      height: 110px;
      width: 110px;
      background-color: #363636;
      border-radius: 50%;
    }
    .preview_infos {
      position: relative;
      bottom: 20px;
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      span {
        color: rgb(35, 82, 157);
      }
      p {
        font-size: 14px;
        color: #818181;
      }
    }
  }
}

@media (max-width: 660px) {
  .first_step_top {
    h1 {
      padding: 10px !important;
      text-align: center;
    }
  }
}

@media (max-width: 768px) {
  .first_step {
    padding: 16px;
    
    .first_step_top {
      width: 100%;
      
      h1 {
        font-size: 24px;
        text-align: center;
        padding: 0 16px;
      }
    }

    .select_images_icon {
      flex-direction: column;
      gap: 16px;
      padding: 0;

      .profile_image_icon,
      .cover_image_icon {
        max-width: none;
        width: 100%;
        min-height: 120px;
        padding: 16px;
      }
    }
  }
}

.onboardModal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 600px !important;
  width: 90vw;
  min-height: 200px;
  height: max-content;
  padding: 10px;

  @media (max-width: 768px) {
    max-width: 100%;
    height: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
  }

  .onboardModalContent {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    max-width: 800px;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    @media (max-width: 768px) {
      padding: 20px;
      gap: 24px;
    }

    p {
      color: rgba(255, 255, 255, 0.9);
      font-size: 16px;
      margin: 0;
      text-align: center;

      @media (max-width: 768px) {
        font-size: 14px;
      }
    }

    .themeSection {
      .themeOptions {
        display: flex;
        gap: 24px;

        .themeOption {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 12px;
          cursor: pointer;
          padding: 12px;
          border-radius: 8px;
          border: 2px solid transparent;
          transition: all 0.2s ease;

          &.selected {
            border-color: var(--primary-color);
            background: rgba(255, 255, 255, 0.05);
          }

          .themePreview {
            width: 100%;
            height: 120px;
            border-radius: 8px;
            overflow: hidden;
            
            &[data-theme="light"] {
              background: #ffffff;
              .previewContent {
                background: #f5f5f5;
              }
            }
            
            &[data-theme="dark"] {
              background: #1a1a1a;
              .previewContent {
                background: #2a2a2a;
              }
            }

            .previewHeader {
              height: 30%;
            }

            .previewContent {
              height: 70%;
            }
          }

          span {
            color: white;
            font-size: 14px;
          }

          &:hover {
            background: rgba(255, 255, 255, 0.05);
          }
        }
      }
    }

    .colorSection {
      .colorPresets {
        display: flex;
        gap: 12px;
        margin-bottom: 24px;
        flex-wrap: wrap;

        .colorPreset {
          width: 36px;
          height: 36px;
          border-radius: 8px;
          cursor: pointer;
          border: 2px solid transparent;
          transition: all 0.2s ease;

          &.selected {
            border-color: white;
            transform: scale(1.1);
          }

          &:hover {
            transform: scale(1.1);
          }
        }
      }

      .colorPickerContainer {
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;

        .react-colorful {
          width: 100% !important;
          max-width: 300px;
        }

        .colorInputRow {
          width: 100%;
          max-width: 300px;

          .hexInput {
            width: 100%;
            padding: 8px 12px;
            border-radius: 6px;
            border: 1px solid rgba(255, 255, 255, 0.2);
            background: rgba(255, 255, 255, 0.1);
            color: white;
            font-size: 14px;

            &::placeholder {
              color: rgba(255, 255, 255, 0.5);
            }

            &:focus {
              outline: none;
              border-color: var(--primary-color);
            }
          }
        }
      }
    }
  }
}
