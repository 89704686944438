@import "../../../../styles/mixins.scss";
$--max-width: 1440px;

.modalContent {
  position: relative;
  background-color: $secondarybg;
  // border: 4px solid rgba(255, 255, 255, 0.05);
  margin: auto;
  text-align: center;
  padding: 0;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  transition: ease-in-out 2s;
  border-radius: 10px;
  border: 1px solid $border-color;
  z-index: 9999;

  backdrop-filter: blur(10px);

  .content {
    padding: 80px 0;

    .text {
      padding: 40px 0;
    }

    h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;

      text-align: center;
      letter-spacing: -0.03em;

      color: #ffffff;
    }

    p {
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;

      text-align: center;

      color: #ffffff;
      opacity: 0.7;
    }
  }

  @include mobile {
    width: 80%;
  }

  .paymentModal {
    padding: 40px 25px;

    .modalHeader {
      margin: auto;
      text-align: center;

      img {
        width: 152px;
        border-radius: 12px;
        border: 1px solid #24243c;
      }

      h6 {
        font-weight: 600;
        font-size: 28px;
        line-height: 36px;
        letter-spacing: -0.03em;

        padding: 30px 0;

        text-align: center;
        color: #ffffff;
      }
    }

    .modalBody {
      .checkbox {
        width: 20px;
        position: relative;

        label {
          width: 20px;
          height: 20px;
          cursor: pointer;
          position: absolute;
          top: 0;
          left: 0;
          //background: #666674;
          //background: linear-gradient(top, #1D1B3A 0%, #1D1B3A 40%, #1D1B3A 100%);
          //border-radius: 10px;
          border: solid 2px #fff;

          &:after {
            content: "";
            width: 25px;
            height: 12px;
            position: absolute;
            top: -5px;
            left: 4px;
            border: 5px solid #fff;
            border-top: none;
            border-right: none;
            background: transparent;
            opacity: 0;
            transform: rotate(-45deg);
          }

          &:hover::after {
            opacity: 0.5;
          }
        }

        input[type="checkbox"] {
          visibility: hidden;

          &:checked + label:after {
            opacity: 1;
          }
        }
      }

      .items {
        padding: 30px 0;

        .checkbox_item {
          display: flex;
          gap: 10px;

          a {
            color: #fff;
            text-decoration: underline;
          }
        }

        .item {
          display: flex;
          justify-content: space-between;
          padding: 8px 0;

          font-weight: 400;
          font-size: 16px;
          line-height: 24px;

          .left {
            display: flex;
            gap: 10px;

            color: #fff;

            span {
              color: #fff;
              font-weight: 300;

              a {
                color: #9469e0;
              }
            }
          }

          .right {
            color: #fff;

            .input {
              display: flex;
              align-items: center;
              justify-content: center;

              width: 100px;

              background: $input-color;
              border-radius: 12px;
              border: 1px solid $border-color;

              padding: 8px 14px;

              input {
                width: 100%;
                margin: 0 16px;

                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
              }

              .minus,
              .plus {
                font-weight: 500;
                font-size: 18px;
                line-height: 24px;

                &:hover {
                  color: #887dea;
                }

                &:disabled {
                  color: #d1cdcd;
                  opacity: 0.3;
                }
              }
            }
          }
        }
      }

      .document {
        text-align: left;
        label {
          font-weight: 300;
          color: #8d8d8d;
        }
      }

      .input_lg {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;

        background: $input-color;
        border-radius: 12px;
        border: 1px solid $border-color;

        padding: 8px 14px;
        margin: 10px 0 !important;

        input {
          width: 100%;
          margin: 0 16px;

          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: left;
        }
      }

      .divider {
        background: #2b2843;
        height: 1px;
        width: 100%;
        margin: 12px auto;
      }

      .buy {
        background: linear-gradient(96.68deg, #62d9ec 0%, #9469e0 105.7%),
          #f6f5ff;
        width: 100% !important;
        height: 55px;

        //padding: 30px 0;
        border-radius: 5px;
        border: none;
        margin-top: 26px;

        span {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #fff;
        }

        &:hover {
          border: none;
        }

        &:disabled {
          filter: grayscale(0.3) opacity(0.5);
        }
      }
    }
  }
}

.loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: auto;
  text-align: center;
}

.loader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.loader div:nth-child(1) {
  animation-delay: -0.45s;
}

.loader div:nth-child(2) {
  animation-delay: -0.3s;
}

.loader div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.paymentProviders {
  //margin: 20px 0;
  margin-bottom: 20px;
  .title {
    text-align: left;
    padding: 15px 0 !important;
  }
  .providers {
    width: 100%;
    padding: 15px 0 !important;

    display: flex;
    gap: 10px;
    justify-content: center;

    @include mobile {
      display: block;
    }
    .provider {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;

      background: rgba(255, 255, 255, 0.05);
      border: $border;
      border-color: $border-color;
      border-radius: 12px;

      label {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 15px 0 !important;
        &:hover {
          cursor: pointer;
        }
      }

      input[type="radio"] {
        opacity: 0;
        position: absolute;
        width: 0;
        height: 0;
        margin: 0;
      }
      @include mobile {
        margin: 20px 0;
      }
    }
    .provider_active {
      background-color: rgba(105, 89, 167, 0.446) !important;
      border: 1px solid $primary !important;
    }
  }
}

.gallery {
  margin-top: 100px;
  padding: 0 20px;

  .info_header {
    padding-bottom: 20px;
    h6 {
      line-height: 33px;
    }
    span {
      font-size: 16px;
    }
  }
  .gallery_items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;

    @include mobile {
      display: block;
    }

    .item {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      overflow: hidden;

      .image {
        width: 100%;
        height: 100%;
        min-height: 350px;
        overflow: hidden;
        border-radius: 10px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

.fan_media {
  margin-top: 100px;
  padding: 0 20px;

  .info_header {
    padding-bottom: 20px;
    h6 {
      line-height: 33px;
    }
    span {
      font-size: 16px;
    }
  }
  .gallery_items {
    display: block;

    @include mobile {
      display: block;
    }

    .item {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      overflow: hidden;

      .image {
        width: 100%;
        height: 300px;
        overflow: hidden;
        border-radius: 10px;
        span img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
  .loop_player {
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    height: 600px;
    object-fit: cover;
    border-radius: $border-radius;
    @include mobile {
      height: 300px;
    }
  }
}
