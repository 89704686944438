@import "./styles/mixins";

html {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: auto;
  //margin-top: 80px;
  margin-top: 75px;
  overflow: auto !important;
  -webkit-overflow-scrolling: touch;

  @include mobile {
    overflow: initial !important;
  }
}

#root {
  width: 100%;
  //overflow: hidden !important;

  @include mobile {
    overflow: initial !important;
  }
}

.container {
  //max-width: 1600px;
  width: 100%;
  height: 100%;
  margin: auto;
}

.App {
  //text-align: center;
}

body,
#root,
.App {
  width: 100%;
  //height: 100%;
  min-height: 100%;
  position: relative;
}

hr {
  border: 0;
  width: 90%;
  height: 1px;
  background: #333;
  background-image: linear-gradient(
    to right,
    #0c0d0f,
    #0000003c,
    #0c0d0f
  ) !important;
  margin: 10px auto 20px auto;
}

br {
  content: "A" !important;
  display: block !important;
  margin-bottom: 1.5em !important;
}

.lazy-load-image-background {
  background: linear-gradient(90deg, #828387, #fff, #828387);
  background-size: 500% 500%;
  animation: gradient 3s ease infinite;
}

.lazy-load-image-loaded {
  background: $mainbg;
  animation: none;
}

// make videos responsive and have a max height of 500px
video {
  position: relative;
  overflow: hidden;
  border-radius: 20px !important;
  object-fit: scale-down !important;
  background: #000;
}

.brevo-conversations {
  @include mobile {
    margin: 0 0 45px 0 !important;
    max-height: calc(100vh - 100px) !important;
  }
}

.content {
  width: 100%;
  height: 100%;

  margin: auto;
  padding: 10px 20px;
  overflow: hidden;

  @include mobile {
    padding: 10px;
  }
}

.overflowHidden {
  overflow: hidden !important;
}

h6 {
  font-weight: 600;
  font-size: 24px;
  line-height: 53px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  span {
    background: linear-gradient(
        123.75deg,
        #9b83f9 53.46%,
        #8164f5 66.94%,
        #27aef8 80.94%,
        #31a2fa 97.26%
      ),
      #ffffff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}

.qr-code {
  padding: 10px;
  background: #fff;
}

.loaderColor {
  display: flex;
  color: $primary !important;
}

.Confetti {
  position: absolute;
  top: -150px !important;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999999 !important;
}

//==========================//
// tiptap styles
//==========================//
.taptap_disabled {
  .tiptap {
    border: none !important;
  }
}

.tiptap {
  border: $border;
  border: solid 2px;
  border-color: $border-color;
  border-radius: $border-radius;
  padding: 10px;
  margin-top: 10px;
  min-height: 100px;
  letter-spacing: 0.00938em;
  box-sizing: border-box;
  outline: none;
  cursor: text;
  position: relative;
  transition: border-color 0.2s ease-in-out;

  &:focus {
    border-color: $secondary !important;
    border: solid 2px;
  }

  p.is-editor-empty:first-child::before {
    color: #70767b;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }

  ol {
    list-style: inherit !important;
    margin: 0 25px;
  }

  ul {
    list-style: inherit !important;
    margin: 0 25px;
  }

  ul[data-type="taskList"] {
    list-style: inherit;
    padding: 0;

    p {
      margin: 0;
    }

    li {
      display: flex;

      > label {
        flex: 0 0 auto;
        margin-right: 0.5rem;
        user-select: none;

        input[type="checkbox"] {
          width: 15px;
          height: 15px;
        }
      }

      > div {
        flex: 1 1 auto;
      }

      ul li,
      ol li {
        display: list-item;
      }

      ul[data-type="taskList"] > li {
        display: flex;
      }
    }
  }
}
//==========================//
// Buttons
//==========================//
.button {
  margin: 40px 0;
  gap: 10px;
  align-items: center;
  width: auto !important;
  font-size: 14px;
  font-weight: bold !important;
  line-height: 20px;
  border-radius: 50px !important;
  padding: 10px 20px !important;

  background-color: $secondary !important;
  border-color: $primary !important;
  color: #fff !important;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    border-color: $primary !important;
  }
}

.button_locked {
  display: flex;
  gap: 10px;
  align-items: center;
  width: auto !important;
  font-size: 14px;
  line-height: 20px;
  color: #fff !important;
  border-radius: 50px !important;
  padding: 10px 20px !important;
  border-color: $primary !important;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    border-color: $primary !important;
  }
}

.login_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;

  img {
    display: none;

    @include tablet {
      display: block;
    }
  }
}

.App-logo {
  margin-top: 100px;
  height: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

a {
  color: $primary;

  &:hover {
    color: #fff !important;
  }
}

h3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;

  color: #f6f5ff;
}

h5 {
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;

  color: #f6f5ff;
}

.errorCard {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .errorMessage {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 400px;
    height: 400px;
    border-radius: 18px;
    background-color: $secondarybg;
    border: solid 1px $border-color;

    text-align: center;

    h1 {
      font-size: 24px;
      font-weight: 900;
      color: #fff;
    }

    span {
      display: block;
      font-size: 16px;
      font-weight: 400;
      color: #a1a1a1;
    }

    .errorMessage_div {
      width: 100%;
      height: 3px;
      background-color: $border-color;

      margin: 10px 0;
    }
  }
}

.breadcrumb {
  display: block;
  padding-bottom: 20px !important;
  overflow: hidden;
  border-radius: 5px;
  counter-reset: flag;
}

.breadcrumb a {
  text-decoration: none;
  outline: none;
  display: block;
  float: left;
  font-size: 12px;
  line-height: 36px;
  color: white;
  /*need more margin on the left of links to accomodate the numbers*/
  padding: 0 10px 0 60px;
  background: #666;
  background: linear-gradient(#666, #333);
  position: relative;
}

/*since the first link does not have a triangle before it we can reduce the left padding to make it look consistent with other links*/
.breadcrumb a:first-child {
  padding-left: 46px;
  border-radius: 5px 0 0 5px;
  /*to match with the parent's radius*/
}

.breadcrumb a:first-child:before {
  left: 14px;
}

.breadcrumb a:last-child {
  border-radius: 0 5px 5px 0;
  /*this was to prevent glitches on hover*/
  padding-right: 20px;
}

/*hover/active styles*/
.breadcrumb a.active {
  background: #333;
  background: linear-gradient(#333, #000);
}

.breadcrumb a.active:after {
  background: #333;
  background: linear-gradient(135deg, #333, #000);
}

/*adding the arrows for the breadcrumbs using rotated pseudo elements*/
.breadcrumb a:after {
  content: "";
  position: absolute;
  top: 0;
  right: -18px;
  /*half of square's length*/
  /*same dimension as the line-height of .breadcrumb a */
  width: 36px;
  height: 36px;
  /*as you see the rotated square takes a larger height. which makes it tough to position it properly. So we are going to scale it down so that the diagonals become equal to the line-height of the link. We scale it to 70.7% because if square's: 
	length = 1; diagonal = (1^2 + 1^2)^0.5 = 1.414 (pythagoras theorem)
	if diagonal required = 1; length = 1/1.414 = 0.707*/
  transform: scale(0.707) rotate(45deg);
  /*we need to prevent the arrows from getting buried under the next link*/
  z-index: 1;
  /*background same as links but the gradient will be rotated to compensate with the transform applied*/
  background: #666;
  background: linear-gradient(135deg, #666, #333);
  /*stylish arrow design using box shadow*/
  box-shadow: 2px -2px 0 2px $secondarybg,
    3px -3px 0 2px rgba(255, 255, 255, 0.1);
  /*
		5px - for rounded arrows and 
		50px - to prevent hover glitches on the border created using shadows*/
  border-radius: 0 5px 0 50px;
}

/*we dont need an arrow after the last link*/
.breadcrumb a:last-child:after {
  content: none;
}

/*we will use the :before element to show numbers*/
.breadcrumb a:before {
  content: counter(flag);
  counter-increment: flag;
  /*some styles now*/
  border-radius: 100%;
  width: 20px;
  height: 20px;
  line-height: 20px;
  margin: 8px 0;
  position: absolute;
  top: 0;
  left: 30px;
  background: linear-gradient(#444, #222);
  font-weight: bold;
}

.flat a,
.flat a:after {
  background: white;
  color: black;
  transition: all 0.5s;

  &:hover {
    cursor: default !important;
  }
}

.flat a:before {
  background: white;
  box-shadow: 0 0 0 1px #ccc;
}

.flat a.active,
.flat a.active:after {
  background: $primary;

  &:hover {
    background: $primary;
    color: #000 !important;
    cursor: pointer;
  }
}

.flat .active {
  &:hover {
    background: $primary;
    color: #000 !important;
    cursor: pointer;
  }
}

.flat a:hover {
  color: #000 !important;
  cursor: default;
}

.loader,
.loader:before,
.loader:after {
  background: #4c4969;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}

.loader {
  color: #4c4969;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: "";
}

.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader:after {
  left: 1.5em;
}

@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }

  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }

  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

.card {
  max-width: 500px;
  margin: 50px auto;
  padding: 50px;
  background: $secondarybg;
  border: $border;
  border-color: $border-color !important;
  border-radius: $border-radius;

  img {
    max-width: 200px;
    margin: 20px 0;
  }

  h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;

    text-align: center;
    letter-spacing: -0.03em;

    color: #ffffff;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    text-align: center;

    color: #ffffff;

    opacity: 0.7;

    padding: 10px 0 30px;
  }
}

.btn_full {
  width: 100% !important;
}

.btn_sm {
  width: fit-content !important;
  padding: 5px 20px !important;
  //width: 150px !important;
}

.btn_solid {
  color: #fff !important;
  background: $primary !important;
  margin: 20px auto !important;
}

.btn_centered {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.btn_default {
  background: $border-color !important;
}

.btn_none {
  background: transparent !important;
  border: none !important;
}

.language-selector {
  display: flex;
  position: relative;
  width: 50px;
  height: 50px;

  button {
    //display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    background: transparent !important;
    border: none !important;
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 800 !important;
    padding: 5px !important;
    margin: 0 5px !important;

    img {
      display: block !important;
      width: 24px;
    }
  }

  .dropdown-options {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;

    padding: 10px;
    border-radius: 5px;
    width: max-content;
    background-color: #fff;

    div {
      display: flex;
      align-items: center;
      //justify-content: center;
      gap: 5px;

      padding: 5px 0;
      cursor: pointer;

      img {
        width: 24px;
      }
    }
  }
}

.textarea_container {
  position: relative;
  width: 100%;
  height: 100%;

  .send_icon {
    position: absolute;
    top: 30%;
    right: 10px;
    width: 30px;
    height: 30px;
    cursor: pointer;

    z-index: 99999;
  }
}

.hideMobile {
  @include mobile {
    display: none;
  }
}

.no-scroll {
  overflow: hidden !important;
}

.moreText {
  display: inline-block;

  p {
    display: contents;
  }
}

.moreTextOpen {
  color: $secondary;
  cursor: pointer;
}

.md_w_modal {
  width: 100% !important;
  max-width: 600px !important;
}
.defaultModal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 400px;
  min-height: 200px;
  height: max-content;
  //padding: 10px !important;
  .defaultModalContent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    .defaultModalButtons {
      margin: 10px;
      button {
        margin: 10px;
      }
    }
  }
}

.userFlowModal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 400px;
  min-height: 200px;
  height: max-content;
  background: #000;
  //padding: 10px !important;
  .defaultModalContent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    .image_text {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: 20px;
      margin: 20px;
      img {
        width: auto;
        height: 60px;
      }
      span {
        font-size: 18px;
        color: white;
        font-weight: bold;
      }
    }

    .button_fan {
      width: 70%;
      border: 1px solid rgb(45, 44, 44);
      padding: 14px 20px;
      display: flex;
      align-items: center;
      gap: 10px;
      border-radius: 5px;
      font-weight: bold;
      color: white;
      margin-bottom: 20px;
      position: relative;
      overflow: hidden;
      transition: color 0.3s ease;
      z-index: 1; /* Ensure the button is above the pseudo-element */
      cursor: pointer;
    }

    .button_fan::before {
      content: "";
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background: rgba(157, 114, 219, 1);
      transition: left 0.5s ease;
      z-index: 0; /* Ensure the pseudo-element is behind the button content */
    }

    .button_fan:hover::before {
      left: 0;
    }

    .button_fan span,
    .button_fan svg {
      position: relative;
      z-index: 1; /* Ensure the text and icon are above the pseudo-element */
    }

    .button_artist {
      width: 70%;
      border: 1px solid rgb(45, 44, 44);
      padding: 14px 20px;
      display: flex;
      align-items: center;
      gap: 10px;
      border-radius: 5px;
      font-weight: bold;
      color: white;
      margin-bottom: 40px;
      position: relative;
      overflow: hidden;
      background: linear-gradient(
        86deg,
        rgba(157, 114, 219, 1) 30%,
        rgba(27, 128, 206, 1) 56%
      );
      transition: color 0.3s ease;
      z-index: 1; /* Ensure the button is above the pseudo-element */
      cursor: pointer;
    }

    .button_artist::before {
      content: "";
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background: rgba(157, 114, 219, 1);
      transition: left 0.5s ease;
      z-index: 0; /* Ensure the pseudo-element is behind the button content */
    }

    .button_artist:hover::before {
      left: 0;
    }

    .button_artist span,
    .button_artist svg {
      position: relative;
      z-index: 1; /* Ensure the text and icon are above the pseudo-element */
    }

    .defaultModalButtons {
      margin: 10px;
      button {
        margin: 10px;
      }
    }
  }
}

// Animations
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  margin-left: 0 !important;
  margin-right: auto;
  cursor: pointer;
  &:hover {
    .swiper-button-prev,
    .swiper-button-next {
      display: grid;
    }
  }
}

.ntf__swiper,
.video__swiper {
  .swiper {
    border-radius: 10px !important;
    overflow: hidden !important;
  }

  .swiper-pagination-bullet {
    width: 9px !important;
    border-radius: 5px !important;
  }
  .swiper-pagination-bullet-active {
    background: #ffff !important;
    width: 13px !important;
  }
  .swiper-button-prev,
  .swiper-button-next {
    color: #fff !important;
    transform: scale(0.5) !important;
    width: 80px !important;
    height: 80px !important;
    transition: 0.2s;
    background: rgba(102, 102, 102, 0.354) !important;
    place-items: center;
    border-radius: 50% !important;
  }
  .swiper-button-prev {
    left: -13px;
  }
  .swiper-button-next {
    right: -13px;
  }
}


.react-colorful {
  width: 100% !important;
  max-width: 100%;
  
  .react-colorful__saturation {
    border-radius: 8px;
    margin-bottom: 16px;
  }
  
  .react-colorful__hue {
    border-radius: 8px;
  }
}