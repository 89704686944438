@import "../../../styles/mixins";
.onboardModal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 400px;
  min-height: 200px;
  height: max-content;
  padding: 10px;
  .onboardModalContent {
    text-align: center;
  }
}

.create_artist_web_page {
  min-height: 100vh;
  padding-bottom: 90px; 
  box-sizing: border-box;
  background: #fafafa;
}

.content {
  position: sticky;
  top: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 100px;
  margin-bottom: 20px;
  padding: 15px 0;
  background-color: $mainbg;
  z-index: 9;
  progress {
    margin-top: 10px;
    border-radius: 7px;
    width: 50%;
    height: 8px;
  }
  progress::-webkit-progress-bar {
    background-color: rgb(43, 43, 42);
    border-radius: 7px;
  }
  progress::-webkit-progress-value {
    background-color: rgb(79, 239, 154);
    border-radius: 7px;
    box-shadow: 0px 0px 0px 0px rgba(79, 239, 154);
  }
  progress::-moz-progress-bar {
    /* style rules */
    box-shadow: 0px 0px 4px 2px rgba(79, 239, 154);
  }
}
.flow {
  position: relative;
}
