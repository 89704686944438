@import "../../../styles/mixins";

.tabs {
  display: flex;
  justify-content: space-evenly;
  //width: 65%;
  max-width: $feed-width;
  margin: 20px auto;
  //margin-bottom: -20px;
  position: relative;
  //left: 17.5%;
  z-index: 2;
  overflow: auto;
  border-bottom: solid 1px $border-color;
  overflow-x: hidden;
  overflow-y: hidden;
  border-radius: 50px;

  @include mobile {
    margin: 20px 20px;
  }

  .tab {
    //flex: 1;
    //flex: none;
    text-align: center;
    padding: 5px;
    font-weight: 600;
    color: #c5c6ca;
    background-color: $border-color;
    border-bottom: solid 1px transparent;
    //background: #111023ab;
    transition: ease-in-out 200ms;
    cursor: pointer;
    width: 50%;

    &:not(:last-child) {
      border-right: solid 1px $border-color;
    }

    &.active {
      color: #fff !important;
      background-color: #313136;
    }

    &:hover {
      opacity: 0, 5;
    }

    span {
      width: 50%;

      &:hover {
        opacity: 0, 5;
      }
    }
  }

  .border {
    border-left: solid 1px #000000;
    border-radius: 20px;
  }

  .active {
    color: $secondary;
    //border-image: linear-gradient(104.36deg, #FCCFF7 10.19%, #E659D6 35.3%, #8877EA 62.03%, #86E2EE 88.37%) 1 !important;
  }
}