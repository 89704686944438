@import "../../../styles/mixins.scss";
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@500;600;700&display=swap");

.loopserve {
  width: 100%;
  height: 340px;

  @include tablet {
    height: 100%;
    min-height: 250px;
  }
  @include mobile {
    height: 100%;
    min-height: 353px;
  }
  iframe {
    width: 100%;

    img {
      width: 100% !important;
    }
  }
  img{
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
  }

  .pr_button {
    min-width: 25%;
    text-align: center;
    position: absolute;
    border-radius: 20px;
    padding: 0px 20px;
    background: rgb(181, 158, 25);
    box-shadow: 0 1px 50px 0 rgb(0, 0, 0);
    margin: 10px;
    cursor: pointer;
    color: white;
    font-weight: 700;
    box-shadow: 0 1px 50px 0 rgb(181, 158, 25), 0 0 10px 1px rgba(0, 0, 0, 0);
    z-index: 2;
    &:hover {
      background: #373a41;
    }
  }
}

.creators_container {
  width: calc(33% - 20px) !important;
  margin: 10px 0;
  background-color: $secondarybg;
  border-radius: $border-radius;
  border: solid 1px $border-color;
  position: relative;
  background: transparent;
  overflow: hidden !important;
  transform: translateY(0px) !important;
  display: flex;

  span {
    width: 100% !important;
  }

  @include tablet {
    width: calc(50% - 20px) !important;
  }

  @include mobile {
    width: 100% !important;
  }

  img {
    height: 100% !important;
    width: 100% !important;
    margin: 0 !important;
    object-fit: cover !important;
    transition: ease-in-out 400ms;
  }
  &:hover {
    background: transparent !important;
    border: solid 1px $primary !important;
    box-shadow: 0 1px 20px 0 rgb(113, 25, 181), 0 0 5px 1px rgba(0, 0, 0, 0) !important;

    .club_details {
      background: #000000d1 !important;
      position: absolute;
      bottom: 0;
      left: 0;
      @include mobile {
        bottom: 0;
      }

      .details {
        opacity: 1;
        bottom: 0;
      }

      .content {
        opacity: 1 !important;
        display: block;
      }
    }
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(358deg, #000, transparent);
    transition: ease-in-out 400ms;
  }
  .equalizer {
    position: absolute;
    left: 70%;
    font-size: 0;
    text-align: center;
    padding: 14px;
  }
  .equalizer_bar {
    transition: transform 150ms linear;
    display: inline-block;
    background: rgba(151, 12, 172, 0.7);
    width: 5px;
    height: 40px;
    border-radius: 1px;
    margin: 0 1px;
    transform: scaleY(0.1);
    transform-origin: center center;
  }
  .vote_button {
    min-width: 25%;
    text-align: center;
    position: absolute;
    border-radius: 20px;
    padding: 0px 20px;
    background: $primary;
    box-shadow: 0 1px 50px 0 rgb(0, 0, 0);
    margin: 10px;
    cursor: pointer;
    color: white;
    font-weight: 700;
    box-shadow: 0 1px 50px 0 rgb(113, 25, 181), 0 0 10px 1px rgba(0, 0, 0, 0);
    z-index: 2;
    &:hover {
      background: #373a41;
    }
  }
  .club_details {
    width: 100%;
    position: absolute;
    bottom: -100px;
    left: 0;
    height: 180px !important;
    border-radius: 10px;
    overflow: hidden !important;
    transition: ease-in-out 400ms;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include tablet {
      bottom: -80px;
      .details {
        opacity: 1 !important;
      }
      .content {
        opacity: 0 !important;
      }
    }

    @include mobile {
      .details {
        opacity: 1 !important;
      }
      .content {
        opacity: 0 !important;
      }
    }
    .details {
      //opacity: 0;
      transition: ease-in-out 400ms;
      p {
        color: #fff;
        font-size: 18px !important;
        font-weight: 700;
        text-align: left;
        padding: 0 10px;
        margin-top: 10px;
      }
    }
    .content {
      padding: 10px;
      opacity: 0;
      font-weight: 400 !important;
      font-size: 13px !important;
      line-height: 18px !important;
      color: #999cb6 !important;
      transition: ease-in-out 400ms;
    }
    .club_details_bottom {
      position: relative;
      padding: 10px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: row;

      .club_details_bottom_song {
        width: 60%;
        white-space: nowrap;
        overflow: hidden;

        span {
          font-size: 14px !important;
          color: white !important;
          transition: ease-in-out 400ms;
          display: inline-block;
          animation: marquee 10s linear infinite;
        }

        @keyframes marquee {
          0% {
            transform: translateX(90%);
          }
          100% {
            transform: translateX(-100%);
          }
        }
      }
      .club_details_bottom_icons {
        width: fit-content;
        border-radius: 50px;
        cursor: pointer;
        display: flex;
        align-items: center;
      }
    }
  }
}
