@import "../../../../styles/mixins";

.notification {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  font-weight: 600;

  &:hover {
    cursor: pointer;
  }
}

.nfts {
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  gap: 20px;
  margin: 10px 0;

  //height: calc(100vh - 340px);
  //overflow: scroll;

/*   @include mobile {
    gap: 0;
    justify-content: space-between;
    margin: 10px;
  } */

  .card {
    flex-grow: 1;
    //flex-basis: 300px;
    max-width: calc(25% - 20px);
    margin: 10px 0;

    background-color: $secondarybg;
    border-radius: $border-radius;
    border: solid 1px $border-color;

    @include tablet {
      max-width: calc(50% - 10px);
      margin: 10px 0;
    }

    @include mobile {
      //max-width: calc(50% - 10px);
      max-width: 100%;
      margin: 10px 0;
    }

    .courseImage {
      width: 100%;
      /* height: 100%;
      min-height: 450px;
      max-height: 450px; */
      overflow: hidden;
      margin: 0 auto;
      border-radius: $border-radius $border-radius 0 0;

      @include mobile {
        width: 100%;
        /* height: 100%; */
      }

      img {
        width: 100%;
        /* height: 100%; */
        object-fit: cover;
      }
    }

    .title {
      font-size: 18px;
      font-weight: bold;
      line-height: 20px;
      color: #fff;
    }

    .details {
      padding: 15px;
    }

    .description {
      font-size: 14px;
      line-height: 20px;
      color: rgba(246, 245, 255, 0.6);
      padding: 10px 0;
    }

    &:hover {
      border: solid 1px $primary !important;
      box-shadow: 0 1px 20px 0 rgb(113, 25, 181), 0 0 5px 1px rgba(0, 0, 0, 0) !important;
      cursor: pointer;
    }
  }
}