@import "../styles/mixins";

@keyframes dropdownSlide {
  from {
    opacity: 0;
    transform: translateY(-8px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  height: fit-content;
  flex-shrink: 0;
  border-bottom: 1px solid $border-color;
  background: $secondarybg;
  padding: 0 10px;
  z-index: 9999;

  @include mobile {
    min-height: 60px;
    padding: 0 5px;
  }

  .items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: auto;
    padding: 15px 20px;

    @include mobile {
      padding: 10px 5px;
    }

    .left {
      width: auto;
      display: flex;
      align-items: center;
      gap: 30px;

      @include mobile {
        gap: 10px;
      }

      .logo {
        padding-top: 2px;
        img {
          height: 38px;
          width: auto;
          object-fit: contain;

          @include mobile {
            height: 32px;
          }
        }
      }

      .nav {
        display: flex;
        align-items: center;
        gap: 30px;
        margin-left: 200px;

        @include tablet {
          margin-left: 40px !important;
        }

        @include mobile {
          display: none;
        }

        a {
          font-weight: 700;
          font-size: 16px;
          line-height: 18px;
          color: #c5c6ca !important;
          text-decoration: none;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            bottom: -5px;
            left: 0;
            width: 0;
            height: 2px;
            background: #c5c6ca;
            transition: all 200ms ease-in-out;
          }
          &:hover {
            &::after {
              width: 100%;
            }
          }
        }

        .artistSignup {
          color: #fff;
          background: $primary;
          border-color: $primary;
          border-radius: 50px;
          padding: 8px 14px;
          font-weight: bold;
          font-size: 16px;
          line-height: 18px;
          text-decoration: none;
          // mask text
          background: linear-gradient(
            120deg,
            $secondary,
            $primary,
            #c5c6ca,
            $primary,
            $secondary
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          // animatate gradient right to left
          background-size: 500% 500%;
          animation: gradient 5s ease infinite;
        }
      }
    }

    .right {
      display: flex;
      align-items: center;
      gap: 16px;
      margin-left: auto;

      @include mobile {
        gap: 4px;
      }

      .loginButton {
        padding: 8px 24px;
        border: 2px solid $text-primary;
        border-radius: 24px;
        background: transparent;
        color: $text-primary;
        font-weight: 600;
        font-size: 14px;
        transition: all 0.2s ease;
        white-space: nowrap;

        @include tablet {
          padding: 6px 16px;
          font-size: 13px;
        }

        @include mobile {
          font-size: 14px;
          border-width: 1.5px;
          width: 100px;
          text-align: center;
        }

        &:hover {
          background: rgba($text-primary, 0.1);
        }
      }

      .signupButton {
        padding: 8px 24px;
        border-radius: 24px;
        background: $text-primary;
        color: $secondarybg;
        font-weight: 600;
        font-size: 14px;
        border: 2px solid $text-primary;
        transition: all 0.2s ease;
        white-space: nowrap;

        @include tablet {
          padding: 6px 16px;
          font-size: 13px;
        }

        @include mobile {
          font-size: 14px;
          border-width: 1.5px;
          width: 100px;
          text-align: center;
        }

        &:hover {
          background: darken($text-primary, 10%);
          border-color: darken($text-primary, 10%);
        }
      }

      .profile {
        display: flex;
        align-items: center;
        gap: 16px;

        @include mobile {
          gap: 6px;
        }

        .button {
          padding: 8px 44px;
          border-radius: 24px;
          font-weight: 800;
          font-size: 16px;
          position: relative;
          background: linear-gradient(120deg, $secondary, darken($text-primary, 15%));
          color: $secondarybg;
          border: none;
          transition: all 0.3s ease;
          overflow: hidden;
          white-space: nowrap;

          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(120deg, rgba($secondary, 0.9), rgba(darken($secondary, 25%), 0.9));
            opacity: 0;
            transition: opacity 0.3s ease;
          }

          &:hover {
            transform: translateY(-1px);
            box-shadow: 0 4px 12px rgba($secondary, 0.2);

            &::before {
              opacity: 1;
            }
          }

          span {
            position: relative;
            z-index: 1;
          }
        }

        img {
          width: 42px;
          height: 42px;
          border-radius: 50%;

          &:hover {
            opacity: 0.7;
          }
        }

        .icon {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: rgba(255, 255, 255, 0.05);
          display: flex;
          align-items: center;
          justify-content: center;

          @include tablet {
            width: 34px;
            height: 34px;
          }

          @include mobile {
            width: 28px;
            height: 28px;
          }

          .tooltip {
            position: absolute;
            bottom: -35px;
            background: rgba(255, 255, 255, 0.05);
            color: white;
            padding: 2px 5px;
            border-radius: 5px;
            z-index: 9;
            display: flex;
            align-items: center;
            span {
              font-weight: bold;
              font-size: 14px;

              @include mobile {
                font-size: 12px;
              }
            }
          }

          .tooltip span {
            margin-left: 5px;
          }
        }
      }
    }
  }

  .logo_section {
    display: grid;
    text-align: center;
    width: auto;

    gap: 10px;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;

    color: #ffffff;

    .logo {
      width: 50px;
      span {
        font-size: 14px;
      }
    }
  }

  .dropdown {
    display: flex;
    position: relative;
    img {
      width: 20px !important;
      height: 20px !important;
      flex-shrink: 0;
    }
  }
  .dropdown_content {
    display: block;
    position: absolute;
    top: 100%;
    right: 10px;
    background: $secondarybg;
    border: 1px solid $border-color;
    border-radius: 0 0 8px 8px;
    padding: 8px 0;
    min-width: 200px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    animation: dropdownSlide 0.2s ease-out forwards;
    transform-origin: top;
    will-change: transform, opacity;

    @include mobile {
      right: 5px;
      min-width: 180px;
      font-size: 12px;

      a {
        padding: 6px 12px !important;
        font-size: 12px !important;
      }

      .dropdown_heading {
        padding: 6px 12px !important;
        font-size: 12px !important;
      }

      .divider {
        margin: 6px 0 !important;
      }
    }

    &.closing {
      animation: dropdownSlide 0.2s ease-in reverse forwards;
    }

    .dropdown_heading {
      font-size: 14px;
      font-weight: 600;
      color: $text-secondary;
      padding: 8px 16px;
      margin: 0;
    }

    .divider {
      height: 1px;
      background-color: $border-color;
      margin: 8px 0;
    }

    a {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 8px 16px;
      color: $text-primary;
      text-decoration: none;
      transition: background-color 0.2s;

      &:hover {
        background-color: rgba($primary-color, 0.1);
      }

      svg {
        color: $text-secondary;
      }
    }
  }
}
